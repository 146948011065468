import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import NewPromotion from '../../../Assets/Images/text-new-promotion.png'

import PromotionCa1 from '../../../Assets/Images/Promotion/ca-1.png'
import PromotionCa2 from '../../../Assets/Images/Promotion/ca-2.png'
import PromotionCa3 from '../../../Assets/Images/Promotion/ca-3.png'
import PromotionCa4 from '../../../Assets/Images/Promotion/ca-4.png'
import PromotionCa5 from '../../../Assets/Images/Promotion/ca-5.png'
import PromotionCa6 from '../../../Assets/Images/Promotion/ca-6.png'
import PromotionCa7 from '../../../Assets/Images/Promotion/ca-7.png'
import PromotionCa8 from '../../../Assets/Images/Promotion/ca-8.png'

import PromotionLO1 from '../../../Assets/Images/Promotion/lo-1.png'

import PromotionSL1 from '../../../Assets/Images/Promotion/sl-1.png'
import PromotionSL2 from '../../../Assets/Images/Promotion/sl-2.png'
import PromotionSL3 from '../../../Assets/Images/Promotion/sl-3.png'
import PromotionSL4 from '../../../Assets/Images/Promotion/sl-4.png'
import PromotionSL5 from '../../../Assets/Images/Promotion/sl-5.png'

import PromotionSP1 from '../../../Assets/Images/Promotion/sp-1.png'
import PromotionSP2 from '../../../Assets/Images/Promotion/sp-2.png'
import PromotionSP3 from '../../../Assets/Images/Promotion/sp-3.png'
import PromotionSP4 from '../../../Assets/Images/Promotion/sp-4.png'
import PromotionSP5 from '../../../Assets/Images/Promotion/sp-5.png'
import PromotionSP6 from '../../../Assets/Images/Promotion/sp-6.png'
import PromotionSP7 from '../../../Assets/Images/Promotion/sp-7.png'
import PromotionSP8 from '../../../Assets/Images/Promotion/sp-8.png'
import PromotionSP9 from '../../../Assets/Images/Promotion/sp-9.png'





export default function PromotionPage() {
    const linkLogin = "https://gamev2.lsm99dna.net/login";
    const linkRegiater = "https://gamev2.lsm99dna.net/register?partner=1297&channel=xbgdna";

    const [bannerPromotionList, setBannerPromotionList] = useState([])
    const [bannerPromotion, setBannerPromotion] = useState([])
    const [proType, setProType] = useState('all')

    const [bannerPro] = useState([
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/ca-1.png",
            type: 'casino'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/ca-2.png",
            type: 'casino'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/ca-3.png",
            type: 'casino'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/ca-4.png",
            type: 'casino'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/ca-5.png",
            type: 'casino'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/ca-6.png",
            type: 'casino'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/ca-7.png",
            type: 'casino'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/ca-8.png",
            type: 'casino'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-1.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-2.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-3.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-4.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-5.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-6.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-7.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-8.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sp-9.png",
            type: 'sport'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sl-1.png",
            type: 'slot'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sl-2.png",
            type: 'slot'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sl-3.png",
            type: 'slot'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sl-4.png",
            type: 'slot'
        },

        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/sl-5.png",
            type: 'slot'
        },
        {
            imageUrl: "https://fs.cdnxn.com/landingDna/Images/Promotion/lo-1.png",
            type: 'lotto'
        },

    ])

    useEffect(() => {
        setBannerPromotionList(bannerPro)
        setBannerPromotion(bannerPro)
    }, [bannerPro])

    const updatePromotion = (proType) => {
        const promotionFilter = bannerPromotionList.filter(i => i.type === proType)
        setBannerPromotion(promotionFilter)
        setProType(proType);
    }

    const updatePromotionAll = (proType) => {
        setBannerPromotion(bannerPromotionList)
        setProType(proType);
    }


    return (
        <div className="div-main-promotion-page promotion-bg-image">
            <div className="session-title-promotion">
                <img src={"https://fs.cdnxn.com/landingDna/Images/text-new-promotion.png"} className="img-promotion-memu" alt="..." />
            </div>

            <div className="seesion-title-catgory">
                <div className={classNames('pro-item', {
                    'active-promotion-category': proType === 'all'
                })} onClick={() => {
                    updatePromotionAll('all');
                }}>
                    <p className='mb-0'>ทั้งหมด</p>
                </div>
                <div className={classNames('pro-item', {
                    'active-promotion-category': proType === 'sport'
                })} onClick={() => {
                    updatePromotion('sport');
                }}>
                    <p className='mb-0'>กีฬา</p>
                </div>
                <div className={classNames('pro-item', {
                    'active-promotion-category': proType === 'casino'
                })} onClick={() => {
                    updatePromotion('casino');
                }}>
                    <p className='mb-0'>คาสิโน</p>
                </div>
                <div className={classNames('pro-item', {
                    'active-promotion-category': proType === 'slot'
                })} onClick={() => {
                    updatePromotion('slot');
                }}>
                    <p className='mb-0'>สล็อต</p>
                </div>
                <div className={classNames('pro-item', {
                    'active-promotion-category': proType === 'lotto'
                })} onClick={() => {
                    updatePromotion('lotto');
                }}>
                    <p className='mb-0'>หวย</p>
                </div>
            </div>
            <div className="session-banner-promotion">
                {bannerPromotion.map((row, index) => (
                    <div className="box-banner-promotion" key={index}>
                        <a href={linkLogin}>
                            <img src={row.imageUrl} draggable={false} className='w-100' alt={row.type} />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}