import React from "react";
import { Col, Row } from "antd";
import { css } from "@emotion/css";
import menuGame from "../../../../Assets/Images/text-menu-game.png";
import menuPlayGame from "../../../../Assets/Images/all-game.png";
import menuSlot from "../../../../Assets/Images/Slot.png";
import menuSport from "../../../../Assets/Images/Sport.png";
import menuCasino from "../../../../Assets/Images/Casino.png";
import menuLotto from "../../../../Assets/Images/Lottery.png";

function contentMenu() {
  const linkLogin = "https://gamev2.lsm99dna.net/login";
  const linkRegiater = "https://gamev2.lsm99dna.net/register?partner=1297&channel=xbgdna";
  return (
    <>
      <div className="text-menu ">
        <img
          src={"https://fs.cdnxn.com/landingDna/Images/text-menu-game.png"}
          className={css`width:80%;
          margin:0 auto;
                        }`}
          alt="..."
        />
      </div>
      <Row justify="center" align="middle" className="content-menu-row">
        <Col xs={10} sm={9} lg={9} xl={9}>
          <a href={linkLogin}>
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/Slot.png"}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
        <Col xs={10} sm={9} lg={9} xl={9}>
          <a href={linkLogin}>
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/Sport.png"}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
        <Col xs={10} sm={9} lg={9} xl={9}>
          <a href={linkLogin}>
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/Casino.png"}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
        <Col xs={10} sm={9} lg={9} xl={9}>
          <a href={linkLogin}>
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/Lottery.png"}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
        <Col xs={10} sm={9} lg={9} xl={9}>
          <a href={linkLogin}>
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/all-game.png"}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </Col>
      </Row>

      {/* <div className="content-menu-row mb">
        <div className="content-menu-left">
          <a href={linkLogin}>
            <img
              src={menuSlot}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>

          <a href={linkLogin}>
            <img
              src={menuCasino}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>

          <a href={linkLogin}>
            <img
              src={menuPlayGame}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </div>
        <div className="content-menu-right">
          <a href={linkLogin}>
            <img
              src={menuSport}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>

          <a href={linkLogin}>
            <img
              src={menuLotto}
              className={css`
                width: 100%;
                &:hover,
                :focus {
                  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
                  transform: translateY(-0.5em);
                }
              `}
              alt="..."
            />
          </a>
        </div>
      </div> */}
    </>
  );
}

export default contentMenu;
